/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { WorkspaceStatus } from './WorkspaceStatus';

export type WorkspaceUpdate = {
    name?: string;
    status?: WorkspaceStatus;
    default_access_scope?: WorkspaceUpdate.default_access_scope;
    default_transcribe_language?: WorkspaceUpdate.default_transcribe_language;
    keywords?: Array<string>;
};

export namespace WorkspaceUpdate {
    export enum default_access_scope {
        ALL = 'all',
        ATTENDEES = 'attendees',
    }

    export enum default_transcribe_language {
        KO = 'ko',
        EN = 'en',
        JA = 'ja',
    }
}
