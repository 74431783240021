/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type TermLong = {
    category: TermLong.category;
    title: string;
    version: number;
    required: boolean;
    id?: number;
    contents: string;
};

export namespace TermLong {
    export enum category {
        SERVICE = 'service',
        PRIVACY_AGREEMENT = 'privacy_agreement',
        MARKETING = 'marketing',
        ADDENDUM_SAVE_PHONE_NUMBER = 'addendum_save_phone_number',
        ALL = 'all',
    }
}
