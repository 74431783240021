var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8e15f1fe34206f92b523b702a6d9bf77f8427028"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

Sentry.init({
    dsn: publicRuntimeConfig.SENTRY_DSN,

    /**
     * 에러 이벤트 샘플링
     */
    sampleRate: 1.0,
    /**
     * transaction 이벤트 샘플링
     */
    tracesSampleRate: 0,
});

Sentry.setTag('phase', publicRuntimeConfig.PHASE);
Sentry.setTag('layer', 'client');
