/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Body_update_locale_v1_user_locale_patch = {
    locale: Body_update_locale_v1_user_locale_patch.locale;
};

export namespace Body_update_locale_v1_user_locale_patch {
    export enum locale {
        KO = 'ko',
        JA = 'ja',
    }
}
