/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Agreement } from '../models/Agreement';
import type { AgreementBase } from '../models/AgreementBase';
import type { AppleAuthentication } from '../models/AppleAuthentication';
import type { Body_claim_password_v1_user_claim_password_post } from '../models/Body_claim_password_v1_user_claim_password_post';
import type { Body_forgot_password_v1_user_forgot_password_post } from '../models/Body_forgot_password_v1_user_forgot_password_post';
import type { Body_logout_v1_user_logout_post } from '../models/Body_logout_v1_user_logout_post';
import type { Body_request_verify_v1_user_request_verify_post } from '../models/Body_request_verify_v1_user_request_verify_post';
import type { Body_signup_v1_user_post } from '../models/Body_signup_v1_user_post';
import type { Body_update_locale_v1_user_locale_patch } from '../models/Body_update_locale_v1_user_locale_patch';
import type { Body_verify_v1_user_verify_post } from '../models/Body_verify_v1_user_verify_post';
import type { Device } from '../models/Device';
import type { EmailAuthentication } from '../models/EmailAuthentication';
import type { GoogleAuthentication } from '../models/GoogleAuthentication';
import type { LoginResponse } from '../models/LoginResponse';
import type { MicrosoftAuthentication } from '../models/MicrosoftAuthentication';
import type { RefreshAuthentication } from '../models/RefreshAuthentication';
import type { ScopedTokenAuthentication } from '../models/ScopedTokenAuthentication';
import type { SharedRecordAuthResponse } from '../models/SharedRecordAuthResponse';
import type { SharedRecordAuthentication } from '../models/SharedRecordAuthentication';
import type { User } from '../models/User';
import type { UserUpdateRequest } from '../models/UserUpdateRequest';

export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * Auth
     * `refresh_token`은 사용시 무효화됨(=1회용)
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public authV1UserAuthPost(
        requestBody:
            | EmailAuthentication
            | GoogleAuthentication
            | AppleAuthentication
            | MicrosoftAuthentication
            | SharedRecordAuthentication
            | RefreshAuthentication
            | ScopedTokenAuthentication
    ): CancelablePromise<LoginResponse | SharedRecordAuthResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/auth',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Who Am I
     * @returns User Successful Response
     * @throws ApiError
     */
    public whoAmIV1UserMeGet(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/user/me',
        });
    }

    /**
     * Signup
     * @param requestBody
     * @returns LoginResponse Successful Response
     * @throws ApiError
     */
    public signupV1UserPost(
        requestBody: Body_signup_v1_user_post
    ): CancelablePromise<LoginResponse> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Withdraw
     * @returns User Successful Response
     * @throws ApiError
     */
    public withdrawV1UserDelete(): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/user',
            errors: {
                412: `Precondition Failed`,
            },
        });
    }

    /**
     * Modify
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public modifyV1UserPatch(requestBody: UserUpdateRequest): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/user',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Logout
     * `push_token`을 등록한적이 없다면, 빈 바디(`{}`)로 호출
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public logoutV1UserLogoutPost(
        requestBody?: Body_logout_v1_user_logout_post
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/logout',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Locale
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public updateLocaleV1UserLocalePatch(
        requestBody: Body_update_locale_v1_user_locale_patch
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/user/locale',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Request Verify
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public requestVerifyV1UserRequestVerifyPost(
        requestBody: Body_request_verify_v1_user_request_verify_post
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/request-verify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                412: `Precondition Failed`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Verify
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public verifyV1UserVerifyPost(
        requestBody: Body_verify_v1_user_verify_post
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/verify',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Forgot Password
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public forgotPasswordV1UserForgotPasswordPost(
        requestBody: Body_forgot_password_v1_user_forgot_password_post
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/forgot-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Claim Password
     * @param requestBody
     * @returns User Successful Response
     * @throws ApiError
     */
    public claimPasswordV1UserClaimPasswordPost(
        requestBody: Body_claim_password_v1_user_claim_password_post
    ): CancelablePromise<User> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/claim-password',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Acceptance
     * @returns Agreement Successful Response
     * @throws ApiError
     */
    public acceptanceV1UserAcceptanceGet(): CancelablePromise<Array<Agreement>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/user/acceptance',
        });
    }

    /**
     * Accept Term
     * @param requestBody
     * @returns Agreement Successful Response
     * @throws ApiError
     */
    public acceptTermV1UserAcceptancePatch(
        requestBody: Array<AgreementBase>
    ): CancelablePromise<Array<Agreement>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/user/acceptance',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Push Token
     * - 내용이 같더라도 주기적으로 호출해주십시오.
     * - Web버전의 경우 login시, token refresh때 호출합니다.
     * - 마지막 호출로 부터 2개월 지날 경우 비활성화된 Device로 판단합니다.
     * - POST /v1/user/logout에 device 인자를 주면 비활성화 처리 됩니다.
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public pushTokenV1UserPushTokenPost(requestBody: Device): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/user/push-token',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
