/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Body_export_to_salesforce_v1_workspace__slug__record__id__salesforce_post } from '../models/Body_export_to_salesforce_v1_workspace__slug__record__id__salesforce_post';
import type { Body_update_phone_number_v1_workspace__slug__record__id__phone_number_put } from '../models/Body_update_phone_number_v1_workspace__slug__record__id__phone_number_put';
import type { Body_update_transcribe_config_v1_workspace__slug__record__id__transcribe_config_put } from '../models/Body_update_transcribe_config_v1_workspace__slug__record__id__transcribe_config_put';
import type { ConversationShare } from '../models/ConversationShare';
import type { Dialog } from '../models/Dialog';
import type { DialogUpdate } from '../models/DialogUpdate';
import type { Monologist } from '../models/Monologist';
import type { Paging_Record_ } from '../models/Paging_Record_';
import type { Record as RecordItem } from '../models/Record';
import type { RecordAccessUpdate } from '../models/RecordAccessUpdate';
import type { RecordCreateRequest } from '../models/RecordCreateRequest';
import type { RecordInsight } from '../models/RecordInsight';
import type { RecordInsightType } from '../models/RecordInsightType';
import type { RecordUpdate } from '../models/RecordUpdate';
import type { SharedRecord } from '../models/SharedRecord';
import type { SharedRecordPutRequest } from '../models/SharedRecordPutRequest';
import type { SpeakerInfoValue } from '../models/SpeakerInfoValue';
import type { SpeakerInfoValueSchema } from '../models/SpeakerInfoValueSchema';

export class RecordService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Record
     * 참고: `dialogs`는 제외하고 내려감.
     * @param slug
     * @param query 검색 키워드
     * @param userId 대화에 참여한 user의 id
     * @param labelIds 레이블 id 리스트
     * @param labelFilterCondition 레이블 필터링 조건
     * @param nextToken pagination token
     * @param legacy 기존 검색 방식과 비교를 위한 플래그
     * @returns Paging_Record_ Successful Response
     * @throws ApiError
     */
    public listRecordV1WorkspaceSlugRecordGet(
        slug: string,
        query?: string,
        userId?: number,
        labelIds?: Array<number>,
        labelFilterCondition: 'and' | 'or' = 'and',
        nextToken?: string,
        legacy: boolean = false
    ): CancelablePromise<Paging_Record_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record',
            path: {
                slug: slug,
            },
            query: {
                query: query,
                user_id: userId,
                label_ids: labelIds,
                label_filter_condition: labelFilterCondition,
                next_token: nextToken,
                legacy: legacy,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Record
     * @param slug
     * @param requestBody
     * @returns Record Successful Response
     * @throws ApiError
     */
    public createRecordV1WorkspaceSlugRecordPost(
        slug: string,
        requestBody: RecordCreateRequest
    ): CancelablePromise<RecordItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/record',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Record
     * @param id
     * @param slug
     * @param query 검색어: 막걸리 보내주세요
     * @returns Record Successful Response
     * @throws ApiError
     */
    public getRecordV1WorkspaceSlugRecordIdGet(
        id: number,
        slug: string,
        query?: string
    ): CancelablePromise<RecordItem> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}',
            path: {
                id: id,
                slug: slug,
            },
            query: {
                query: query,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Record
     * @param slug
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteRecordV1WorkspaceSlugRecordIdDelete(
        slug: string,
        id: number
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/record/{id}',
            path: {
                slug: slug,
                id: id,
            },
            errors: {
                422: `Validation Error`,
                425: `Too Early`,
            },
        });
    }

    /**
     * Update Record
     * @param id
     * @param slug
     * @param requestBody
     * @returns Record Successful Response
     * @throws ApiError
     */
    public updateRecordV1WorkspaceSlugRecordIdPatch(
        id: number,
        slug: string,
        requestBody: RecordUpdate
    ): CancelablePromise<RecordItem> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/record/{id}',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Record Access
     * @param id
     * @param slug
     * @param requestBody
     * @returns Record Successful Response
     * @throws ApiError
     */
    public updateRecordAccessV1WorkspaceSlugRecordIdAccessPatch(
        id: number,
        slug: string,
        requestBody: RecordAccessUpdate
    ): CancelablePromise<RecordItem> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/record/{id}/access',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Transcribe Config
     * 레코드에 대한 문자변환 설정을 업데이트합니다.
     * 변환이 되기 전에 없데이트 된 값만 유효하게 적용됩니다.
     * (complete upload 이후에 업데이트한 내역은 재전사 할 경우에만 적용됩니다.)
     * @param id
     * @param slug
     * @param requestBody
     * @returns void
     * @throws ApiError
     */
    public updateTranscribeConfigV1WorkspaceSlugRecordIdTranscribeConfigPut(
        id: number,
        slug: string,
        requestBody?: Body_update_transcribe_config_v1_workspace__slug__record__id__transcribe_config_put
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/workspace/{slug}/record/{id}/transcribe-config',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Initiate Upload
     * 응답의 S3 Presigned URL에 업로드
     * - HTTP Method: PUT
     * - HTTP Header:
     * - `Content-Length`: record 생성시 입력한 `filesize` 값과 동일해야 함
     * - `Content-Type`: 파일 확장자에 따라 적절한 `mimetype`을 지정해야 함 (ex: `audio/mpeg`, `video/mp4`)
     * - `Content-Disposition`: `"attachment; filename*=UTF-8''" + URLEncode(filename)`
     * - curl 예시
     * ```bash
     * curl -XPUT \
     * --data-binary '@뉴스.mp3' \
     * -H 'Content-Type: audio/mpeg' \
     * -H "Content-Disposition: attachment; filename*=UTF-8''%EB%89%B4%EC%8A%A4.mp3" \
     * $PRESIGNED_URL
     * ```
     * @param id
     * @param slug
     * @returns string Successful Response
     * @throws ApiError
     */
    public initiateUploadV1WorkspaceSlugRecordIdUploadPost(
        id: number,
        slug: string
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/record/{id}/upload',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Complete Upload
     * @param id
     * @param slug
     * @returns Record Successful Response
     * @throws ApiError
     */
    public completeUploadV1WorkspaceSlugRecordIdUploadCompletePost(
        id: number,
        slug: string
    ): CancelablePromise<RecordItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/record/{id}/upload/complete',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Retranscribe
     * @param id
     * @param slug
     * @returns Record Successful Response
     * @throws ApiError
     */
    public retranscribeV1WorkspaceSlugRecordIdRetranscribePost(
        id: number,
        slug: string
    ): CancelablePromise<RecordItem> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/record/{id}/retranscribe',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
                425: `Too Early`,
            },
        });
    }

    /**
     * Download
     * @param id
     * @param slug
     * @returns string Successful Response
     * @throws ApiError
     */
    public downloadV1WorkspaceSlugRecordIdDownloadGet(
        id: number,
        slug: string
    ): CancelablePromise<string> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}/download',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
                425: `Too Early`,
            },
        });
    }

    /**
     * Subtitle
     * @param ext
     * @param id
     * @param slug
     * @returns any Successful Response
     * @throws ApiError
     */
    public subtitleV1WorkspaceSlugRecordIdSubtitleExtGet(
        ext: 'vtt' | 'srt',
        id: number,
        slug: string
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}/subtitle.{ext}',
            path: {
                ext: ext,
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
                425: `Too Early`,
            },
        });
    }

    /**
     * Update Dialog
     * @param id
     * @param slug
     * @param requestBody
     * @returns Dialog Successful Response
     * @throws ApiError
     */
    public updateDialogV1WorkspaceSlugRecordIdDialogPatch(
        id: number,
        slug: string,
        requestBody: Array<DialogUpdate>
    ): CancelablePromise<Array<Dialog>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/record/{id}/dialog',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Speaker Info
     * @param id
     * @param slug
     * @param requestBody
     * @returns SpeakerInfoValue Successful Response
     * @throws ApiError
     */
    public updateSpeakerInfoV1WorkspaceSlugRecordIdSpeakerInfoPatch(
        id: number,
        slug: string,
        requestBody: Record<string, SpeakerInfoValueSchema>
    ): CancelablePromise<Record<string, SpeakerInfoValue>> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/workspace/{slug}/record/{id}/speaker-info',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update Phone Number
     * @param id
     * @param slug
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public updatePhoneNumberV1WorkspaceSlugRecordIdPhoneNumberPut(
        id: number,
        slug: string,
        requestBody: Body_update_phone_number_v1_workspace__slug__record__id__phone_number_put
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/workspace/{slug}/record/{id}/phone-number',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Conversation Shares
     * 레코드의 대화 점유율 통계를 가져옵니다.
     * @param id
     * @param slug
     * @returns ConversationShare Successful Response
     * @throws ApiError
     */
    public getConversationSharesV1WorkspaceSlugRecordIdConversationSharesGet(
        id: number,
        slug: string
    ): CancelablePromise<Array<ConversationShare>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}/conversation-shares',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Monologists
     * 레코드의 대화 독점 비율 통계를 가져옵니다.
     * @param id
     * @param slug
     * @returns Monologist Successful Response
     * @throws ApiError
     */
    public getMonologistsV1WorkspaceSlugRecordIdMonologistsGet(
        id: number,
        slug: string
    ): CancelablePromise<Array<Monologist>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}/monologists',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Insight
     * 레코드의 인사이트를 가져옵니다.
     * @param insightType
     * @param id
     * @param slug
     * @returns RecordInsight Successful Response
     * @throws ApiError
     */
    public getInsightV1WorkspaceSlugRecordIdInsightInsightTypeGet(
        insightType: RecordInsightType,
        id: number,
        slug: string
    ): CancelablePromise<RecordInsight> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}/insight/{insight_type}',
            path: {
                insight_type: insightType,
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Insights
     * 레코드의 인사이트를 가져옵니다.
     * @param id
     * @param slug
     * @returns RecordInsight Successful Response
     * @throws ApiError
     */
    public getInsightsV1WorkspaceSlugRecordIdInsightGet(
        id: number,
        slug: string
    ): CancelablePromise<Array<RecordInsight>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}/insight',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Export To Salesforce
     * @param id
     * @param slug
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public exportToSalesforceV1WorkspaceSlugRecordIdSalesforcePost(
        id: number,
        slug: string,
        requestBody: Body_export_to_salesforce_v1_workspace__slug__record__id__salesforce_post
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/record/{id}/salesforce',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Add Record Label
     * @param id
     * @param slug
     * @param labelId
     * @returns void
     * @throws ApiError
     */
    public addRecordLabelV1WorkspaceSlugRecordIdLabelLabelIdPost(
        id: number,
        slug: string,
        labelId: number
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/record/{id}/label/{label_id}',
            path: {
                id: id,
                slug: slug,
                label_id: labelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Remove Record Label
     * @param id
     * @param slug
     * @param labelId
     * @returns void
     * @throws ApiError
     */
    public removeRecordLabelV1WorkspaceSlugRecordIdLabelLabelIdDelete(
        id: number,
        slug: string,
        labelId: number
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/record/{id}/label/{label_id}',
            path: {
                id: id,
                slug: slug,
                label_id: labelId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Get Shared Record
     * @param id
     * @param slug
     * @returns SharedRecord Successful Response
     * @throws ApiError
     */
    public getSharedRecordV1WorkspaceSlugRecordIdSharedGet(
        id: number,
        slug: string
    ): CancelablePromise<SharedRecord> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/record/{id}/shared',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Put Shared Record
     * @param id
     * @param slug
     * @param requestBody
     * @returns SharedRecord Successful Response
     * @throws ApiError
     */
    public putSharedRecordV1WorkspaceSlugRecordIdSharedPut(
        id: number,
        slug: string,
        requestBody: SharedRecordPutRequest
    ): CancelablePromise<SharedRecord> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/workspace/{slug}/record/{id}/shared',
            path: {
                id: id,
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Shared Record
     * @param slug
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteSharedRecordV1WorkspaceSlugRecordIdSharedDelete(
        slug: string,
        id: number
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/record/{id}/shared',
            path: {
                slug: slug,
                id: id,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Shared Record
     * 참고: `dialogs`는 제외하고 내려감.
     * @param slug
     * @param nextToken pagination token
     * @returns Paging_Record_ Successful Response
     * @throws ApiError
     */
    public listSharedRecordV1WorkspaceSlugSharedRecordGet(
        slug: string,
        nextToken?: string
    ): CancelablePromise<Paging_Record_> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/shared-record',
            path: {
                slug: slug,
            },
            query: {
                next_token: nextToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
