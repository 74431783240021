import logo_svg from '/public/callabo/logo.svg';
import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import { PathName } from 'src/modules/PathName';
import { Paths } from 'src/modules/Paths';

const Header = (): JSX.Element => {
    return (
        <Wrapper>
            <Link href={new PathName(Paths.WorkspaceIndex).toString()} passHref>
                <Image
                    src={logo_svg.src}
                    width={96}
                    height={23}
                    alt="logo"
                    layout="fixed"
                    style={{ zIndex: 100 }}
                />
            </Link>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: 24px 0 0 24px;
    margin-bottom: 28px;
    position: relative;
`;

export default Header;
