/* istanbul ignore file */

/* tslint:disable */

/* eslint-disable */
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { Integration } from '../models/Integration';
import type { IntegrationCreateRequest } from '../models/IntegrationCreateRequest';
import type { SalesforceContact } from '../models/SalesforceContact';
import type { SalesforceLead } from '../models/SalesforceLead';
import type { SalesforceOppty } from '../models/SalesforceOppty';
import type { SlackChannelIntegration } from '../models/SlackChannelIntegration';
import type { SlackIntegration } from '../models/SlackIntegration';
import type { SlackIntegrationCreate } from '../models/SlackIntegrationCreate';

export class IntegrationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * List Slack Integrations
     * Slack 연동 목록을 조회합니다.
     * @returns SlackIntegration Successful Response
     * @throws ApiError
     */
    public listSlackIntegrationsV1SlackIntegrationGet(): CancelablePromise<
        Array<SlackIntegration>
    > {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/slack-integration',
        });
    }

    /**
     * Create Slack Integration
     * Slack 연동을 새로 추가합니다.
     * @param requestBody
     * @returns SlackIntegration Successful Response
     * @throws ApiError
     */
    public createSlackIntegrationV1SlackIntegrationPost(
        requestBody: SlackIntegrationCreate
    ): CancelablePromise<SlackIntegration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/slack-integration',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Slack Integration
     * Slack 연동을 제거합니다. Slack team에 대한 모든 user 레벨 연동이 제거되면 team 레벨 연동도 제거됩니다.
     * @param id
     * @returns void
     * @throws ApiError
     */
    public deleteSlackIntegrationV1SlackIntegrationIdDelete(id: number): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/slack-integration/{id}',
            path: {
                id: id,
            },
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Slack Callback
     * @returns any Successful Response
     * @throws ApiError
     */
    public slackCallbackV1SlackIntegrationCallbackPost(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/slack-integration/callback',
        });
    }

    /**
     * Get Slack Channel Integration
     * Slack channel incoming-webhook 연동을 조회합니다.
     * @param slug
     * @returns SlackChannelIntegration Successful Response
     * @throws ApiError
     */
    public getSlackChannelIntegrationV1WorkspaceSlugSlackGet(
        slug: string
    ): CancelablePromise<SlackChannelIntegration> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            errors: {
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Slack Channel Integration
     * Slack channel incoming-webhook 연동을 새로 추가합니다.
     * @param slug
     * @param requestBody
     * @returns SlackChannelIntegration Successful Response
     * @throws ApiError
     */
    public createSlackChannelIntegrationV1WorkspaceSlugSlackPost(
        slug: string,
        requestBody: SlackIntegrationCreate
    ): CancelablePromise<SlackChannelIntegration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                404: `Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Slack Integration
     * @param slug
     * @returns void
     * @throws ApiError
     */
    public deleteSlackIntegrationV1WorkspaceSlugSlackDelete(slug: string): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/slack',
            path: {
                slug: slug,
            },
            errors: {
                403: `Forbidden`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Google Notification
     * https://developers.google.com/calendar/api/guides/push#receiving-notifications
     * @param id
     * @param xGoogChannelId
     * @param xGoogMessageNumber
     * @param xGoogResourceId
     * @param xGoogResourceState
     * @param xGoogResourceUri
     * @param xGoogChannelExpiration
     * @param xGoogChannelToken
     * @returns void
     * @throws ApiError
     */
    public googleNotificationV1WorkspaceSlugIntegrationIdGoogleNotificationPost(
        id: number,
        xGoogChannelId: string,
        xGoogMessageNumber: number,
        xGoogResourceId: string,
        xGoogResourceState: 'sync' | 'exists' | 'not_exists',
        xGoogResourceUri: string,
        xGoogChannelExpiration?: string,
        xGoogChannelToken?: string
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/integration/{id}/google-notification',
            path: {
                id: id,
            },
            headers: {
                'X-Goog-Channel-ID': xGoogChannelId,
                'X-Goog-Message-Number': xGoogMessageNumber,
                'X-Goog-Resource-ID': xGoogResourceId,
                'X-Goog-Resource-State': xGoogResourceState,
                'X-Goog-Resource-URI': xGoogResourceUri,
                'X-Goog-Channel-Expiration': xGoogChannelExpiration,
                'X-Goog-Channel-Token': xGoogChannelToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Outlook Notification
     * @param id
     * @param validationToken validation token
     * @returns any Successful Response
     * @throws ApiError
     */
    public outlookNotificationV1WorkspaceSlugIntegrationIdOutlookNotificationPost(
        id: number,
        validationToken?: string
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/integration/{id}/outlook-notification',
            path: {
                id: id,
            },
            query: {
                validationToken: validationToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Outlook Lifecycle Notification
     * @param id
     * @param validationToken validation token
     * @returns any Successful Response
     * @throws ApiError
     */
    public outlookLifecycleNotificationV1WorkspaceSlugIntegrationIdOutlookLifecycleNotificationPost(
        id: number,
        validationToken?: string
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/integration/{id}/outlook-lifecycle-notification',
            path: {
                id: id,
            },
            query: {
                validationToken: validationToken,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List Integration
     * @param slug
     * @returns Integration Successful Response
     * @throws ApiError
     */
    public listIntegrationV1WorkspaceSlugIntegrationGet(
        slug: string
    ): CancelablePromise<Array<Integration>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/integration',
            path: {
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Integration
     * @param slug
     * @param requestBody
     * @returns Integration Successful Response
     * @throws ApiError
     */
    public createIntegrationV1WorkspaceSlugIntegrationPost(
        slug: string,
        requestBody: IntegrationCreateRequest
    ): CancelablePromise<Integration> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/workspace/{slug}/integration',
            path: {
                slug: slug,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                409: `Conflict`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * Delete Integration
     * @param id
     * @param slug
     * @returns void
     * @throws ApiError
     */
    public deleteIntegrationV1WorkspaceSlugIntegrationIdDelete(
        id: number,
        slug: string
    ): CancelablePromise<void> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/workspace/{slug}/integration/{id}',
            path: {
                id: id,
                slug: slug,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Salesforce Search
     * @param slug
     * @param kw search keyword
     * @param keyword search keyword
     * @param t
     * @returns any Successful Response
     * @throws ApiError
     */
    public salesforceSearchV1WorkspaceSlugSalesforceSobjectsGet(
        slug: string,
        kw?: string,
        keyword?: string,
        t?: Array<'contact' | 'lead' | 'oppty'>
    ): CancelablePromise<Array<SalesforceLead | SalesforceOppty | SalesforceContact>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/workspace/{slug}/salesforce/sobjects',
            path: {
                slug: slug,
            },
            query: {
                kw: kw,
                keyword: keyword,
                t: t,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
